export const wyscreen = {
    host: 'wss://www.wyscreen.de:15676/ws',

    logins: {
        1: {
            clientId: 'screen-1',
            userName: 'meiller:meiller_screen_1',
            password: 'EAGATBxSmqNK',
        },
        2: {
            clientId: 'screen-2',
            userName: 'meiller:meiller_screen_2',
            password: 'SALht5PCuQJW',
        },
        3: {
            clientId: 'screen-3',
            userName: 'meiller:meiller_screen_3',
            password: '8TNR7cE6rFky',
        },
        4: {
            clientId: 'screen-4',
            userName: 'meiller:meiller_screen_4',
            password: 'ug4F4gXXSNND',
        },
        5: {
            clientId: 'screen-5',
            userName: 'meiller:meiller_screen_5',
            password: '8TNR7cE6rFky',
        },
        6: {
            clientId: 'screen-6',
            userName: 'meiller:meiller_screen_6',
            password: 'ug4F4gXXSNND',
        }
    },

    mqttTopicTpls: {
      triggerPing: 'screen/SCREEN_ID/trigger/ping',
      triggerUpdate: 'screen/SCREEN_ID/trigger/update',
      triggerGroup: 'screen/SCREEN_ID/trigger/group',
      groupMessages: 'screen/SCREEN_ID/group/messages',
      requestContent: 'screen/SCREEN_ID/content/request',
      receivedContent: 'screen/SCREEN_ID/content/received',

      screenContentUpdate: 'screen/SCREEN_ID/update/content',
      screenMediaUpdate: 'screen/SCREEN_ID/update/media',
      screenGroupUpdate: 'screen/SCREEN_ID/update/group',

      commandReload: 'screen/SCREEN_ID/command/reload',
    },
}
