<template>
    <div class="position-relative w-100 h-100 center-wrapper">
        <div class="position-absolute top-50 start-50 translate-middle w-100 inner-wrapper">
            <div id="content-wrapper" >
                <div class="position-relative" v-show="isBootScreen">
                    <img :src="require('@/assets/meiller-logo.png')" class="position-absolute top-50 start-50 translate-middle meiller-logo" alt="Meiller Logo" />
                    <div class="mx-auto text-center powered-text">
                        powered by <img :src="require('@/assets/wyscreen-logo-white.svg')" class="mh-30" alt="" />
                    </div>
                    <div class="pt-50">
                        <div class="dot-falling mt-5 mx-auto"></div>
                    </div>
                </div>

                <div v-show="!isBootScreen">
                    <div id="carousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-touch="false" data-bs-interval="false">
                        <div id="carousel-inner"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel } from 'bootstrap'
import { wyscreen } from './wyscreen'
import PahoMQTT from 'paho-mqtt'
import Dexie from 'dexie';

export default {
  name: 'App',
  data() {
    return {
      screenId: null,
      screenGroupId: null,
      isGroupMaster: false,
      isConnectedToGroup: false,
      groupMode: null,
      groupMembers: [],
      mqttClient: null,
      db: null,
      carousel: null,
      dataSyncPolling: null,
      mqttPing: null,
      groupTrigger: null,
      missingMediaCheckerInterval: null,
      slidingIntervalTimer: null,
      isBootScreen: true,
      mqttReconnectCounter: 0,
      currentMediaName: '',
      mediaCurrent: {
        hash: '',
        contents: []
      },
      mediaNext: {
        hash: '',
        contents: []
      },
      mediaUpcoming: {
        contents: []
      }
    }
  },

  created() {
    this.initScreenParams()
    this.initDb()
    this.initMqtt()
  },

  mounted() {
    let that = this

    if (this.groupMode === 'not_paired' || this.isGroupMaster) {
      window.setTimeout(async () => {
        await that.assembleScreenOutput()
        that.sendGroupSlidingMessage(0)
      }, 5000)
    }

    window.setTimeout(async () => {
      that.startMqttSubscriptions()
      that.mqttTriggerUpdate()
      that.mqttTriggerGroup()
      await that.checkForMissingMedia()
    }, 5000)

    this.dataSyncPolling = setInterval(() => {
      that.mqttTriggerUpdate()
    }, 3600000)

    this.mqttPing = setInterval(() => {
      that.mqttTriggerPing()
    }, 60000)

    this.groupTrigger = setInterval(() => {
      that.mqttTriggerGroup()
    }, 30000)
  },

  methods: {
    initScreenParams() {
      let queryString = window.location.search
      let urlParams = new URLSearchParams(queryString)
      this.screenId = urlParams.get('screen')
    },

    showBootScreen() {
      this.isBootScreen = true
    },

    reloadWindow() {
      location.reload()
    },

    initDb() {
      console.log("initDb")
      this.db = new Dexie('wyscreen')

      this.db.version(1).stores({
        media: 'name',
        settings: 'key',
      })

      let that = this
      this.db.settings.get('media')
        .then((media) => {
          if (typeof media === 'object') {
            that.mediaNext.hash = media.value.hash
            that.mediaNext.contents = media.value.contents
          }
        })

      this.db.settings.get('group')
          .then((group) => {
            if (typeof group === 'object') {
              that.screenGroupId = group.value.id
              that.isGroupMaster = group.value.is_master
              that.groupMode = group.value.mode
            }
          })
    },

    initMqtt() {
      console.log("initMqtt")
      let that = this

      let loginData = wyscreen.logins[this.screenId]
      if (typeof loginData === 'undefined') {
        alert('Invalid screen data')
      }

      //this.mqttClient = new PahoMQTT.Client(wyscreen.host, loginData.clientId + '__' + this.makeId(5))
      this.mqttClient = new PahoMQTT.Client(wyscreen.host, loginData.clientId)
      this.mqttClient.onConnectionLost = this.mqttOnConnectionLost
      this.mqttClient.onMessageArrived = this.mqttOnMessageArrived
      this.mqttClient.traceFunction = this.mqttTrace
      this.mqttClient.connect({
        userName: loginData.userName,
        password: loginData.password,
        onSuccess: function() {
          console.log('initMqtt connected')
        },
        onFailure: function(x) {
          console.log('initMqtt failed', x)
        },
        keepAliveInterval: 10,
        reconnect: true
      });
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },

    makeId(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
      }

      return result;
    },

    mqttPublish(topic, payload) {
      let that = this

      try {
        console.log('mqttPublish', topic)

        let message = new PahoMQTT.Message(String(payload))
        message.destinationName = topic
        message.qos = 0;

        this.mqttClient.send(message)
      } catch (error) {
        console.log('mqttPublish error', error)
        window.setTimeout(() => {
          that.mqttPublish(topic, payload)
        }, 2000)
      }
    },

    mqttSubscribe(topic) {
      try {
        this.mqttClient.subscribe(topic, 0)
      } catch (error) {
        console.log('mqttSubscribe error', error)
      }
    },

    mqttTriggerUpdate() {
      this.mqttPublish(
        wyscreen.mqttTopicTpls.triggerUpdate.replace(/SCREEN_ID/g, this.screenId),
        '1'
      )
    },

    mqttTriggerPing() {
      this.mqttPublish(
          wyscreen.mqttTopicTpls.triggerPing.replace(/SCREEN_ID/g, this.screenId),
          '1'
      )
    },

    mqttTriggerGroup() {
      this.mqttPublish(
          wyscreen.mqttTopicTpls.triggerGroup.replace(/SCREEN_ID/g, this.screenId),
          '1'
      )
    },

    mqttRequestContent(mediaName) {
      console.log('mqttRequestContent', mediaName);
      this.mqttPublish(
        wyscreen.mqttTopicTpls.requestContent.replace(/SCREEN_ID/g, this.screenId),
        mediaName
      )
    },

    mqttReceivedContent(mediaName) {
      this.mqttPublish(
          wyscreen.mqttTopicTpls.receivedContent.replace(/SCREEN_ID/g, this.screenId),
          mediaName
      )
    },

    mqttSendGroupMessage(message) {
      let that = this
      this.groupMembers.forEach(function (groupMemberId) {
        if (String(groupMemberId) !== String(that.screenId)) {
          let topic = wyscreen.mqttTopicTpls.groupMessages.replace(/SCREEN_ID/g, groupMemberId)
          that.mqttPublish(topic, JSON.stringify(message))
          console.log('published', topic, JSON.stringify(message))
        }
      })
    },

    startMqttSubscriptions() {
      this.mqttSubscribe(wyscreen.mqttTopicTpls.screenContentUpdate.replace(/SCREEN_ID/g, this.screenId))
      this.mqttSubscribe(wyscreen.mqttTopicTpls.screenMediaUpdate.replace(/SCREEN_ID/g, this.screenId))
      this.mqttSubscribe(wyscreen.mqttTopicTpls.screenGroupUpdate.replace(/SCREEN_ID/g, this.screenId))
      this.mqttSubscribe(wyscreen.mqttTopicTpls.commandReload.replace(/SCREEN_ID/g, this.screenId))
      this.mqttSubscribe(wyscreen.mqttTopicTpls.groupMessages.replace(/SCREEN_ID/g, this.screenId))
    },

    mqttOnConnectionLost(responseObject) {
      let that = this
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage)
      }
      this.mqttReconnectCounter++

      if (this.mqttReconnectCounter > 10) {
        this.reloadWindow()
      } else {
        setTimeout(() => {
          that.initMqtt()
        }, 2000)
      }
    },

    async mqttOnMessageArrived(message) {
      console.log('mqttProcessMessage', message.topic)

      try {
        let data = JSON.parse(message.payloadString)

        console.log('mqttProcessMessagedata', data)

        let screenUpdateContentTopic = wyscreen.mqttTopicTpls.screenContentUpdate.replace(/SCREEN_ID/g, this.screenId)
        let screenUpdateMediaTopic = wyscreen.mqttTopicTpls.screenMediaUpdate.replace(/SCREEN_ID/g, this.screenId)
        let screenUpdateGroupTopic = wyscreen.mqttTopicTpls.screenGroupUpdate.replace(/SCREEN_ID/g, this.screenId)
        let groupMessagesTopic = wyscreen.mqttTopicTpls.groupMessages.replace(/SCREEN_ID/g, this.screenId)
        let commandReloadTopic = wyscreen.mqttTopicTpls.commandReload.replace(/SCREEN_ID/g, this.screenId)

        switch (message.topic) {
          case screenUpdateContentTopic:
            await this.processScreenContentData(data)
            break

          case screenUpdateMediaTopic:
            await this.processScreenUpdateMediaData(data)
            break

          case screenUpdateGroupTopic:
            await this.processScreenUpdateGroup(data)
            break

          case groupMessagesTopic:
            await this.processGroupMessage(data)
            break;

          case commandReloadTopic:
            this.reloadWindow()
            break

          default:
        }
      } catch (error) {
        console.log('mqttProcessMessage error', error)
      }
    },

    mqttTrace(data) {
      console.log(data)
    },

    async processScreenUpdateMediaData(data) {
      if (data.name !== null && typeof data.name === 'string') {
        this.db.media.put(data)
        this.mqttReceivedContent(data.name)
      }
    },

    async processScreenUpdateGroup(data) {
      // console.log("processScreenUpdateGroup")

      let currGroupMode = this.groupMode;
      this.screenGroupId = data.id
      this.isGroupMaster = data.is_master
      this.groupMode = data.mode
      this.groupMembers = data.members

      await this.db.settings.put({
        key: 'group',
        value: data
      })

      // if (currGroupMode !== data.mode) {
      //   this.resetCurrentMedia()
      // }

      //if ((currGroupMode === 'paired_1' || currGroupMode === 'paired_2') && data.mode === 'not_paired') {
        this.startAssembleScreenOutputTimer()
      //}
    },

    async processGroupMessage(data) {
      if (this.isGroupMaster) {
        return
      }

      // console.log(data)
      switch (this.groupMode) {
        case 'paired_0':
        case 'paired_1':
          if (typeof data.action !== 'undefined') {
            switch (data.action) {
              case 'slide_to_position':
                await this.slideToPosition(data.position)
                break;
            }
          }
          break;

        case 'paired_2':
          if (typeof data.action !== 'undefined') {
            switch (data.action) {
              case 'start_video':
                await this.startPaired2VideoSequence()
                break;
            }
          }
          break;

        default:
      }
    },

    async slideToPosition(position) {
      if ((this.currentMediaName === '' || this.carousel === null) && position === 0) {
        await this.assembleScreenOutput()

        return
      }

      if (typeof this.mediaCurrent.contents[position] !== 'string') {
        return
      }

      if (this.getNextSlideMediaName() === this.mediaCurrent.contents[position]) {
        this.showNextSlide()
      }
    },

    async startPaired2VideoSequence() {
      if ((this.currentMediaName === '' || this.carousel === null)) {
        await this.assembleScreenOutput()
      }
      this.startVideo()
    },

    getNextSlideMediaName() {
      let currIndex = this.mediaCurrent.contents.indexOf(this.currentMediaName)
      let nextIndex = currIndex + 1
      if (nextIndex >= this.mediaCurrent.contents.length) {
        nextIndex = 0
      }

      return this.mediaCurrent.contents[nextIndex]
    },

    async processScreenContentData(data) {
      if (typeof data === 'object') {
        if (typeof data.media.current === 'object') {
          this.mediaNext = data.media.current
          await this.db.settings.put({
            key: 'media',
            value: data.media.current
          })
        }

        if (data.media.upcoming !== null && typeof data.media.upcoming === 'object') {
          this.mediaUpcoming = data.media.upcoming
        }
      }

      await this.checkForMissingMedia()
    },

    async checkForMissingMedia() {
      await this.checkForMissingMediaDeep(this.mediaCurrent)
      await this.checkForMissingMediaDeep(this.mediaNext)
      await this.checkForMissingMediaDeep(this.mediaUpcoming)
    },

    async checkForMissingMediaDeep(mediaData) {
      let that = this
      mediaData.contents.forEach(await async function (mediaName) {
        that.db.media.where('name').equals(mediaName).count(function (count) {
          if (count < 1) {
            that.mqttRequestContent(mediaName)
          }
        })
      })
    },

    mustReassembleScreenOutput() {
      // console.log("mustReassembleScreenOutput isBootScreen", this.isBootScreen)
      // console.log("mustReassembleScreenOutput mediaCurrent.hash", this.mediaCurrent.hash)
      // console.log("mustReassembleScreenOutput mediaNext.hash", this.mediaNext.hash)

      if (this.isBootScreen === true && this.mediaNext.contents.length > 0) {
        return true
      }

      return (this.mediaCurrent.hash !== this.mediaNext.hash)
    },

    async checkNeededMediasInDb(contents) {
      let mediaNamesDbQty = 0
      await this.db.media.where('name').anyOf(contents).count(function(qty) {
        mediaNamesDbQty = qty
      });

      let result = (contents.length === mediaNamesDbQty)
      if (result !== true) {
        await this.checkForMissingMedia()
      }

      return result
    },

    startAssembleScreenOutputTimer() {
      let that = this

      setTimeout(() => {
        that.assembleScreenOutput()
      }, 2000)
    },

    async assembleScreenOutput() {
      // console.log("assembleScreenOutput")
      let isMustReassembleScreenOutput = this.mustReassembleScreenOutput()
      // console.log("isMustReassembleScreenOutput", isMustReassembleScreenOutput)
      if (isMustReassembleScreenOutput === false) {
        this.startAssembleScreenOutputTimer()
        return
      }

      let isMediasCheckedSuccess = await this.checkNeededMediasInDb(this.mediaNext.contents)
      console.log("isMediasCheckedSuccess", isMediasCheckedSuccess)
      if (isMediasCheckedSuccess !== true) {
        this.startAssembleScreenOutputTimer()
        return
      }

      this.mediaCurrent = this.mediaNext

      if (this.mediaCurrent.contents.length === 0) {
        this.assembleCarouselError()
        return
      }

      await this.assembleCarousel()
    },

    resetCurrentMedia() {
      this.mediaCurrent.hash = ''
      this.mediaCurrent.contents = []
    },

    assembleCarouselError() {
      console.log("assembleCarouselError")
      this.resetCurrentMedia()
      this.showBootScreen()
      this.startAssembleScreenOutputTimer()
    },

    async assembleCarousel() {
      console.log('assembleCarousel')
      let that = this
      this.carouselDestroy()

      if (this.mediaCurrent.contents.length === 0) {
        this.assembleCarouselError()
        return
      }

      let slidesHtmlData = []
      let mediaDataAll = {}
      let mediaNames = []

      this.mediaCurrent.contents.forEach(function(mediaName) {
        mediaDataAll[mediaName] = null
        mediaNames.push(mediaName)
      })

      await this.db.media.where('name').anyOf(mediaNames).each(function(mediaData) {
        mediaDataAll[mediaData.name] = mediaData
      });

      let isActive = true
      this.mediaCurrent.contents.forEach(function(mediaName) {
        if (typeof mediaDataAll[mediaName] === 'object') {
          let mediaData = mediaDataAll[mediaName]
          let slideHtml = that.getSlideHtml(mediaData, isActive)
          slidesHtmlData.push(slideHtml)
          if (isActive) {
            that.currentMediaName = mediaData.name
          }
          isActive = false
        }
      })

      if (slidesHtmlData.length === 0) {
        this.assembleCarouselError()
        return
      }

      this.isBootScreen = false
      document.querySelector('#carousel-inner').innerHTML = slidesHtmlData.join('')
      this.carouselInit()
    },

    getSlideHtml(mediaData, isActive) {
      // mediaData.width
      // mediaData.height
      // mediaData.length
      // mediaData.type
      // mediaData.name
      // mediaData.content

    let newMediumWidth;
    let newMediumHeight;

    let mediumRatio = mediaData.width / mediaData.height;
    let screenRatio = window.innerWidth / window.innerHeight;

    if(mediumRatio < screenRatio){
        newMediumWidth = "auto";
        newMediumHeight = window.innerHeight;
    }else{
        newMediumWidth = window.innerWidth;
        newMediumHeight = "auto";
    }

      let html = ''
      let active = isActive ? ' active' : ''

      let slidingInterval = mediaData.length
      if (parseInt(slidingInterval) === 0) {
        slidingInterval = '10'
      }

      switch (mediaData.type) {
        case 'image/png':
          html = '<div class="carousel-item$active" data-media-id="$mediaName" data-media-type="image" data-sliding-interval="$slidingInterval">' +
                 '    <img src="data:image/png;base64,$content" id="v-$mediaName" class="d-block mx-auto" width="'+newMediumWidth+'" height="'+newMediumHeight+'" alt="">' +
                 '</div>'
          break

        case 'video/webm':
          html = '<div class="carousel-item$active" data-media-id="$mediaName" data-media-type="video" data-video-id="v-$mediaName" data-sliding-interval="$slidingInterval">' +
                 '    <video muted id="v-$mediaName" class="d-block mx-auto" preload="auto" width="'+newMediumWidth+'" height="'+newMediumHeight+'" poster="' + require("@/assets/noposter.png") + '">' +
                 '        <source src="data:video/webm;base64,$content">' +
                 '    </video>' +
                 '</div>'
          break;

        default:
      }

      html = html.replace(/\$active/g, active)
      html = html.replace(/\$slidingInterval/g, slidingInterval)
      html = html.replace(/\$mediaName/g, mediaData.name)
      html = html.replace(/\$content/g, mediaData.content)

      return html
    },

    carouselInit() {
      console.log('carouselInit')
      let that = this
      let carouselElement = document.getElementById('carousel')
      this.carousel = new Carousel(carouselElement, {
        interval: false
      })

      carouselElement.addEventListener('slide.bs.carousel', function (event) {
        that.carouselEventSlide(event);
      })

      try {
        let firstSlideElement = document.querySelector('div.carousel-item.active')
        this.slideActions(firstSlideElement)
      } catch (error) {
        console.log(error.message)
      }
    },

    startSlidingTimer(slidingInterval) {
      console.log('startSlidingTimer', slidingInterval)
      let that = this
      this.stopSlidingTimer()
      this.slidingIntervalTimer = window.setTimeout(function () {
        try {
          that.showNextSlide()
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }, parseInt(slidingInterval) * 1000)
    },

    stopSlidingTimer() {
      if (this.slidingIntervalTimer) {
        clearTimeout(this.slidingIntervalTimer)
        this.slidingIntervalTimer = null
      }
    },

    sendGroupSlidingMessage(position) {
      if (this.isGroupMaster === true) {
        console.log('sendGroupSlidingMessage')
        switch (this.groupMode) {
          case 'paired_0':
          case 'paired_1':
            this.mqttSendGroupMessage({
              action: 'slide_to_position',
              position: position
            })
            break;

          case 'paired_2':
            this.mqttSendGroupMessage({
              action: 'start_video',
              position: position
            })
            break;
        }
      }
    },

    showNextSlide() {
      if (this.mustReassembleScreenOutput()) {
        this.sendGroupSlidingMessage(0)
        this.assembleScreenOutput()
      } else {
        if (this.mediaCurrent.contents.length === 1) {
          this.sendGroupSlidingMessage(0)
          let firstSlideElement = document.querySelector('div.carousel-item.active')
          this.slideActions(firstSlideElement)
        } else {
          this.sendGroupSlidingMessage(this.findNextMediaPosition())
          this.carousel.next()
        }
      }
    },

    carouselDestroy() {
      this.stopSlidingTimer()
      if (this.carousel !== null) {
        this.carousel.pause()
        this.carousel.dispose()
        this.carousel = null
      }
      document.querySelector('#carousel-inner').innerHTML = ''
    },

    carouselEventSlide(event) {
      console.log('carouselEventSlide', 'event.to', event.to)
      if (event.to === 0) {
        let isMustReassembleScreenOutput = this.mustReassembleScreenOutput()
        if (isMustReassembleScreenOutput) {
          this.assembleScreenOutput()
        } else {
          this.slideActions(event.relatedTarget)
        }
      } else {
        this.slideActions(event.relatedTarget)
      }
    },

    findNextMediaPosition() {
      let nextPosition = this.mediaCurrent.contents.indexOf(this.currentMediaName) + 1
      if (nextPosition >= this.mediaCurrent.contents.length) {
        nextPosition = 0
      }

      return nextPosition
    },

    slideActions(element) {
      if (element === null) {
        this.reloadWindow()
      }

      this.currentMediaName = element.getAttribute('data-media-id')

      this.startVideo(
          element.getAttribute('data-video-id')
      )

      if (this.groupMode === 'not_paired' || this.isGroupMaster) {
        this.startSlidingTimer(
            element.getAttribute('data-sliding-interval')
        )
      }
    },

    startVideo() {
      try {
        let element = document.querySelector('div[data-media-id="' + this.currentMediaName + '"]')
        let mediaType = element.getAttribute('data-media-type')
        if (mediaType === 'video') {
          console.log("startVideo")
          let videoId = element.getAttribute('data-video-id')
          let videoElement = document.getElementById(videoId)

          videoElement.currentTime = 0
          let playPromise = videoElement.play()
          if (playPromise !== undefined) {
            playPromise.then(() => {
              console.log('Video has started')
            })
            .catch(error => {
              console.log(error)
            });
          }
        }
      } catch (error) {
        console.log(error.message)
      }
    }
  }
};
</script>

<style>
    @import './assets/css/main.css';
    @import './assets/css/vendor/three-dots.css';
</style>
